import { screenSizes } from '@bridebook/ui';

/**
 * Returns screen type based on the width and aligned with CSS breakpoints:
 * - mobile - less than screenSm
 * - tablet - screenSm and screenMd
 * - desktop - screenLg and above
 *
 * If width is not provided (eg. from server-side), then it's based on the passed
 * user-agent information.
 */
export const getScreenTypeByWidth = ({
  width,
  isMobileUA,
  isTabletUA,
}: {
  width: number;
  isMobileUA: boolean;
  isTabletUA: boolean;
}) => {
  const { tabletSm, desktopSm } = screenSizes;

  if (!width) {
    return {
      isMobile: isMobileUA,
      isTablet: isTabletUA,
      isDesktop: !isMobileUA && !isTabletUA,
    };
  }

  const isMobile = width < tabletSm;
  const isTablet = width >= tabletSm && width < desktopSm;
  const isDesktop = width >= desktopSm;

  return {
    isMobile,
    isTablet,
    isDesktop,
  };
};
