export const env = {
  COUPLESIDE_URL: process.env.NEXT_PUBLIC_COUPLESIDE_URL,
  MICROSITE_URL: process.env.NEXT_PUBLIC_MICROSITE_URL,
  SEGMENTID: process.env.NEXT_PUBLIC_SEGMENTID,
  STATIC: process.env.NEXT_PUBLIC_STATIC,
  S3_URL: process.env.NEXT_PUBLIC_S3_URL,
  CMS_URL: process.env.NEXT_PUBLIC_CMS_URL,
  CMS_LANDING_URL: process.env.NEXT_PUBLIC_CMS_LANDING_URL,
  FIREBASE: {
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  },
  API_ZENDESK_WIDGET: process.env.NEXT_PUBLIC_API_ZENDESK_WIDGET,
  STRIPE_API_KEY: process.env.NEXT_PUBLIC_STRIPE_API_KEY,
  TALKJS_APP_ID: process.env.NEXT_PUBLIC_TALKJS_APP_ID,
  SECRET_COOKIE_PASSWORD: process.env.SECRET_COOKIE_PASSWORD,
  ENQUIRIES_USER_LIMIT: process.env.NEXT_PUBLIC_ENQUIRIES_USER_LIMIT,
  LIVE: process.env.NEXT_PUBLIC_DATABASE === 'production',
  IS_VISUAL_TEST: process.env.NEXT_PUBLIC_APP_MODE === 'visual',
  BEST_OF_BRITAIN: process.env.NEXT_PUBLIC_BEST_OF_BRITAIN === 'enabled',
  WEBFLOW_BASE_URL: process.env.NEXT_PUBLIC_WEBFLOW_BASE_URL,
  APPSFLYER_DEV_KEY: process.env.NEXT_PUBLIC_APPSFLYER_DEV_KEY,
  APPSTORE_ID: process.env.NEXT_PUBLIC_APPSTORE_ID,
  CROWDIN_IN_CONTEXT: process.env.NEXT_PUBLIC_CROWDIN_IN_CONTEXT === 'true',
  IS_PRODUCTION_BUILD: process.env.NODE_ENV === 'production',
  BUILD_ID: process.env.BUILD_ID,
  CUSTOMER_IO_SITE_ID: process.env.CUSTOMER_IO_SITE_ID,
  CUSTOMER_IO_API_KEY: process.env.CUSTOMER_IO_API_KEY,
  IMGIX_CLIENT_DOMAIN: process.env.NEXT_PUBLIC_IMGIX_CLIENT_DOMAIN,
  IMGIX_CLIENT_TOKEN: process.env.IMGIX_CLIENT_TOKEN,
  RECAPTCHA_PUBLIC_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_PUBLIC_KEY ?? '',
  RECAPTCHA_SECRET: process.env.RECAPTCHA_SECRET,
  PLATFORM_VERSION: process.env.NEXT_PUBLIC_PLATFORM_VERSION,
  ENCRYPTION_SECRET: process.env.ENCRYPTION_SECRET,
};
