const mobileSm = 360;
const mobileLg = 480;
const tabletSm = 768;
const tabletLg = 992;
const desktopSm = 1260;
const desktopLg = 1440;

const screenSizes = {
  mobileSm,
  mobileSmMax: mobileLg - 1,
  mobileLg,
  mobileLgMax: tabletSm - 1,
  tabletSm,
  tabletSmMax: tabletLg - 1,
  tabletLg,
  tabletLgMax: desktopSm - 1,
  desktopSm,
  desktopSmMax: desktopLg - 1,
  desktopLg,
};

export default screenSizes;
